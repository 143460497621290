import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { base_url } from "./constant";
import { toast } from "react-toastify";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";

function DashBoard() {
  const navigate = useNavigate();
  const [taskShow, setTaskShow] = useState("");
  const [profileData, setProfileData] = useState("");
  const token = JSON.parse(localStorage.getItem("subuserloginTokem"));
  const Token = token.accessToken;
  const header = {
    headers: {
      Authorization: `Bearer ${Token}`,
      "content-type": "application/json",
      // "Content-Type": "multipart/form-data",
    },
  };
  useEffect(() => {
    // profile api
    axios.get(base_url + "profile", header).then((res) => {
      setProfileData(res.data.data);
    });
    //    DashBoard api
    try {
      axios.get(base_url + "user-dashbaord", header).then((res) => {
        if (res.data.status == false) {
          toast("Please Enter Valid User Email");
          navigate("/");
        }
        setTaskShow(res.data.data);
      });
      // .catch(err => {
      //     toast("Please Enter Valid User Email", { autoClose: 3000 });
      //     setTimeout(() => {
      //         navigate("/")
      //     }, 4000)
      // })
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleTask = (status, colorClassName, taskName,colorCode) => {
    navigate(`/task-show/${status}`);
    localStorage.setItem("colorCode", colorClassName);
    localStorage.setItem("taskName", taskName);
    localStorage.setItem("bgcolorCode", colorCode);
  };

  const [clockTimeInOut, setClockTimeInOut] = useState();
  const TimeCount = async () => {
    await axios.get(base_url + "clock-in-clock-out", header).then((res) => {
      setClockTimeInOut(res?.data?.data);
    });
  };

  const ClockInClockOut = async () => {
    await axios.get(base_url + "get-clockin-clockout", header).then((res) => {
      setClockTimeInOut(res?.data?.data);
    
    });
  };

  useState(() => {
    ClockInClockOut();
  }, []);

  // const clockStatus = localStorage.getItem("ClockInStatus");
  const clockInTime = moment(clockTimeInOut?.login_time, "HH:mm:ss").format(
    "h:mm:ss A"
  );
  const clockOutTime = moment(clockTimeInOut?.logout_time, "HH:mm:ss").format(
    "h:mm:ss A"
  );

  

  return (
    <Layout>
      <div className="customer-page login-page my-3 dashboard-page">
        <div className="d-flex align-items-center justify-content-between hr-line">
          <h2
            className="head mb-0"
            style={{ textTransform: "capitalize", fontSize: "16px" }}
          >
            DashBoard: 
            <span style={{ fontWeight: "700", color:"#f2754d" }}>({profileData.name})</span>
          </h2>
          <p style={{ fontSize: "14px" }}>
            <b>Total Task: </b>
            {taskShow.totalTaskCount}
          </p>
        </div>

        <div className={clockTimeInOut?.status === false ? "clockField card bg-panel-info text-white p-2" : "clockField card bg-panel-info text-white p-2"}>
          <div className="clockField-main">
            <div className="clockField-left">
              <AiOutlineClockCircle className="fontIcon" />
            </div>
            <div className="clockField-left-right">
              <div className="clock-content">
                {/* <p>clock in time / clock out time </p> */}
                {/* <p>Clock Started at : {clockInTime}  </p> */}

                {clockTimeInOut?.status === false ? (
                  <p>Clock Out at : {clockOutTime} </p>
                ) : clockTimeInOut?.status === true ? (
                  <p>Clock Started at : {clockInTime} </p>
                ) : (
                  ""
                )}

                {/* {clockData.status === "1" && (
                  <p>Clock Started at : {clockInTime} </p>
                )} */}

                <div className="text-right">
                  {clockTimeInOut?.status === true ? (
                    <Link
                      to="#"
                      className="card-text-clockin d-inline-block clock_in"
                      onClick={TimeCount}
                    >
                      Clock Out
                    </Link>
                  ) : clockTimeInOut?.status === false ? (
                    <Link
                      to="#"
                      className="card-text-clockin d-inline-block clock_out"
                      onClick={TimeCount}
                    >
                      Clock In
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="card-text-clockin d-inline-block clock_in"
                      onClick={TimeCount}
                    >
                      Clock In
                    </Link>
                  )}
                  {/* {clockTimeInOut?.status === false && <p>Clock In </p>} */}

                  {/* <Link
                    to="#"
                    className="card-text-clockin d-inline-block"
                    onClick={TimeCount}
                  >
                    Clock In
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <p style={{ fontSize: "14px", fontWeight: "500" }}>
          {/* Show All Type Of Task */}
        </p>
        <div className={clockTimeInOut?.status === false || !clockTimeInOut ? "row display_or_not" : "row"} >
          <div className="col-md-6 mb-3 col-sm-6">
            <div
              class="card bg-gradient-danger text-white"
              onClick={() => handleTask(3, "bg-gradient-danger", "New","#f6aec7")}
            >
              <p>New</p>
              <h3 class="card-text">
                {taskShow.totalNewTask ? taskShow.totalNewTask : "0"}
              </h3>
            </div>
          </div>

          <div className="col-md-6 mb-3 col-sm-6">
            <div
              class="card bg-gradient-success text-white"
              onClick={() => handleTask(2, "bg-gradient-success", "Follow Up","#07cdae")}
            >
              <p>Follow Up</p>
              <h3 class="card-text">{taskShow.totalFollowUpTask}</h3>
            </div>
          </div>

          {/* <div className="col-md-6 mb-3 col-sm-6">
            <div
              class="card bg-gradient-info text-white"
              onClick={() => handleTask(4, "bg-gradient-info", "Month","#64bebe")}
            >
              <p>Month</p>
              <h3 class="card-text">
                {taskShow.totalMonthTask ? taskShow.totalMonthTask : "0"}
              </h3>
            </div>
          </div> */}

          <div className="col-md-6 mb-3 col-sm-6">
            <div
              class="card bg-gradient-secondary text-white"
              onClick={() => handleTask(0, "bg-gradient-secondary", "Close","#a3a3a3")}
            >
              <p>Close</p>
              <h3 class="card-text">{taskShow.totalCloseTask}</h3>
            </div>
          </div>

          <div className="col-md-6 mb-3 col-sm-6">
            <div
              class="card bg-gradient-purple text-white"
              onClick={() => handleTask(1, "bg-gradient-purple", "Assigned","#8abe8c")}
            >
              <p>Assigned</p>
              <h3 class="card-text">{taskShow.totalassignedTask}</h3>
            </div>
          </div>

          {/* <div className="col-md-6 mb-3 col-sm-6">
            <div
              class="card bg-gradient-warning text-white"
              onClick={() => handleTask(5, "bg-gradient-warning", "Open","#fcb05d")}
            >
              <p>Open</p>
              <h3 class="card-text">{taskShow.totalOpenTask}</h3>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default DashBoard;
