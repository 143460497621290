import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import TaskDetail from "./TaskDetail";
import { base_url } from "./constant";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { ColorRing } from 'react-loader-spinner'
import { Link, useNavigate, useParams } from "react-router-dom";
function Task() {
  const Task_status = useParams();
  const task_status = Task_status.status;

const [loader,setloader] = useState(true)
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState([]);
  const [branchWiseTask, setBranchWiseTask] = useState([]);
  const token = JSON.parse(localStorage.getItem("subuserloginTokem"));
  const Token = token.accessToken;
  const header = {
    headers: {
      Authorization: `Bearer ${Token}`,
      "content-type": "application/json",
    },
  };
  const date = new Date();

  useEffect(() => {
    axios.get(base_url + `task-list/${task_status}`, header).then((res) => {
    
      setTaskList(res.data.data);
      // setBranchWiseTask(res.data.branchwiseData);
      setloader(false)
    });
  }, []);
  const handleDetail = (id) => {
    // alert(id);
    navigate(`/task-detail/${id}`);
  };
  const bgColor = localStorage.getItem("colorCode");
  const taskName = localStorage.getItem("taskName");
  const colorCode = localStorage.getItem("bgcolorCode");

  return (
    <Layout>
      <div
        className=""
      >
        <div className="d-flex align-item-center justify-content-between hr-line">
          <h2 className="head mb-0" style={{color: `${colorCode}`}}>{taskName} Task</h2>
        </div>
        <div className="task-show">
          {taskList?.length == "0" ? (
            <p className="text-center">No Task</p>
          ) : (
            taskList?.map((taskListShow) => {
              return (
                <div className={`task-head ${bgColor}`}>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>Customer Name:- </b>
                      {taskListShow.customer_name}
                    </p>
                    <p className='tasktitle ' style={{backgroundColor: `${colorCode}`}}>{taskName}</p>
                  </div>
                  <p>
                    <b>Phone Number:- </b>
                    <Link to={`tel:${taskListShow.mobile_no}`}>
                  {" "}
                  {taskListShow.mobile_no}
                </Link>
                  
                  </p>
                  <div className="moredetailBox">
                  <p
                    className="taskDetail moredetailBtn" style={{color: `${colorCode}`}}
                    onClick={() => handleDetail(taskListShow.id)}
                  >
                    More Detail
                  </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      
{loader ? (
    <p className="text-center">
  <ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>
</p>
):(
        <div>
          {branchWiseTask?.map((branchWiseTaskShow) => {
        
            return (
              <div className={`task-head ${bgColor}`}>
                <div className="d-flex align-items-center justify-content-between">
                  <p>
                    <b>Customer Name:- </b>
                    {branchWiseTaskShow.customer_name}
                  </p>
                  <p className='tasktitle' style={{backgroundColor: `${colorCode}`}}>{taskName}</p>
                </div>
                <p>
                  <b>Account Number:- </b>
                  {"xxxxxx" +
                    `${branchWiseTaskShow.loan_account_number}`.slice(6, 15)}
                </p>
                {/* <p><b>Phone Number:- </b><Link to={`tel:${taskListShow.mobile_no}`}> {taskListShow.mobile_no}</Link></p>
                                    <p><b>Address:- </b>{taskListShow.address}</p>
                                    <p><b>City:- </b>{taskListShow.city}</p> */}
                <p
                  className="taskDetail" style={{color: `${colorCode}`}}
                  onClick={() => handleDetail(branchWiseTaskShow.id)}
                  
                >
                  More Detail
                </p>
              </div>
            );
          })}
        </div>
        )}
      </div>
    </Layout>
  );
}

export default Task;
