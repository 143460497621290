import React from "react";
import Layout from "./Layout";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const ClockIn = () => {
  return (
    <Layout>
      <div className="customer-page login-page my-3 dashboard-page">
        <div className="d-flex align-items-center justify-content-between hr-line">
          <h2
            className="head mb-0"
            style={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "red",
            }}
          >
            Clock In here to see your task Details.
          </h2>
        </div>

        {/* <div className="row">
          <div className=" mb-3 px-2 " style={{ width: "100%" }}>
            <div class="card bg-gradient-danger text-white p-2">
              <div className="col-md-12">
                <AiOutlineClockCircle />
              </div>
              <div className="col-md-12 clock-in-card">
                <Link class="card-text-clockin">Clock In</Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="clockField card bg-gradient-danger text-white p-2">
          <div className="clockField-main">
            <div className="clockField-left">
              <AiOutlineClockCircle className="fontIcon" />
            </div>
            <div className="clockField-left-right">
              <div className="clock-content">
                <p>clock in time / clock out time </p>
                <div className="text-right">
                  <Link to="/dashboard" className="card-text-clockin d-inline-block">Clock In</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ClockIn;
