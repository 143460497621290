import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "./constant";
import {FILE_VIEW_URL} from "./constant";

function Navbar() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState("");
  const token = JSON.parse(localStorage.getItem("subuserloginTokem"));
  const Token = token.accessToken;
  const header = {
    headers: {
      Authorization: `Bearer ${Token}`,
      "content-type": "application/json",
    },
  };

  useEffect(() => {
    axios.get(base_url + "profile", header).then((res) => {
      setProfileData(res.data.data);
    });
  }, []);
  const profileId = profileData.id;
  const handleLogout = () => {
    axios.post(base_url + `logout/${profileId}`).then((res) => {
      navigate("/");
    });
  };

  return (
    <>
      <header className="header_section">
        <nav className="navbar custom_nav-container navbar-light">
          <Link to="/dashboard" className="navbar-brand mr-5">
            <div class="logo-survey">
              <img src="/assets/images/team-icon.png" />
              <span className="teamtrackLogo">UserTeamTrack</span>
            </div>
            {/* <h3>Survey </h3> */}
          </Link>

          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          > */}
            {/* <span className="navbar-toggler-icon"></span> */}
            <div className="nav-profile position-relative navbar-toggler"  type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <img src={`${FILE_VIEW_URL}`+ profileData.profile_image} alt="profie=le" className=" profile_img"
           />
            {/* <span className="d-block">Anshu Singh</span> */}
            </div>
          {/* </button> */}
          <div
            className="collapse navbar-collapse bg-white"
            id="navbarSupportedContent"
          >
            <div className="d-flex ml-auto flex-column flex-lg-row align-items-center p-2">
              <ul className="navbar-nav  ">
                {/* <li class="nav-item dropdown">
                  <Link class="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    My Profile
                  </Link>
                  <div class="dropdown-menu profile-menu" aria-labelledby="navbarDropdown">

                    <p class="dropdown-item" >{profileData.name}</p>
                    <p class="dropdown-item" >{profileData.email}</p>

                    <p class="dropdown-item" >{profileData.city}</p>
                    <p class="dropdown-item" >{profileData.contact_number}</p>
                    <p class="dropdown-item" >{profileData.country}</p>
                    <div class="dropdown-divider"></div>
                    <p class="dropdown-item" >{profileData.address}</p>
                  </div>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/profile">
                    My Profile{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/task-show/1">
                    My Task{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link nobg"
                    to="#"
                    onClick={handleLogout}
                  >
                    {" "}
                    Logout{" "}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
