import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { base_url } from "../constant";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Footer";

function Login() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(base_url + "user-login", inputValue)
      .then((res) => {
        localStorage.setItem(
          "subuserloginTokem",
          JSON.stringify({
            login: true,
            accessToken: res.data.accessToken,
          })
        );
        toast.success(res.data.message, { autoClose: 3000 });
        setTimeout(() => {
          navigate("/dashboard");
          //   navigate("/clock-in");
        }, 4000);
      })
      .catch((err) => {
        toast.warning(" You Enter Incorrect Password");
      });
  };

  return (
    <>
    <div className="authPage">
      <div className="container-fluid auth-wrapper d-flex justify-content-center">
        {/* Same as */}
        <ToastContainer position="top-right" />
        <div className="content-wrapper d-flex align-item-center justify-content-center flex-column p-3 page-width">
          <Link to="">
            <div class="logoDesign">
              <img src="/assets/images/team-icon.png" />
              <span className="teamtrackLogo">UserTeamTrack</span>
            </div>
          </Link>

          <div className="login-page">
            <div className="form">
              <h2 className="head">Login</h2>
              <form className="login-form" onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="useremail"
                  name="email"
                  value={inputValue.email}
                  onChange={handleChange}
                />
                <div className="position-relative">
                  <input
                    className="pr-4"
                    type={passwordShow ? "text" : "password"}
                    placeholder="password"
                    name="password"
                    value={inputValue.password}
                    onChange={handleChange}
                  />
                  <i
                    className={
                      passwordShow
                        ? "fa fa-eye eyeIcon"
                        : "fa fa-eye-slash eyeIcon"
                    }
                    onClick={() => setPasswordShow(!passwordShow)}
                  ></i>
                </div>
                <Link className="forgot-pass" to="/forgot">
                  Forgot Password
                </Link>
                <div className="btn">
                  {" "}
                  <button type="submit">login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='footer footer2'> */}
      <div className="footer footer2">
        <Footer />
      </div>
      </div>
    </>
  );
}

export default Login;
