import axios from "axios";
import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { base_url } from "../constant";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Footer";
function NewPassword() {
  const Token = useParams();
  const navigate = useNavigate();
  const forgotToken = Token.token;
  console.log("token", forgotToken);
  const [newPassword, setNewPassword] = useState({
    new_password: "",
    new_Cpassword: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordCnfShow, setPasswordCnfShow] = useState(false);
  const [error, setError] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;
    setNewPassword({ ...newPassword, [name]: value });
  };

  //validation
  const isValid = () => {
    let errors = {};
    if (newPassword.new_password != newPassword.new_Cpassword) {
      errors.check_password = "password did not match";
      console.log("pass did not matchnewPassword");
    }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = isValid();
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, handle form submission here
      // console.log("Form submitted successfully!");
    } else {
      // Form is invalid, display validation errors
      // console.log("Form error found!");
      setError(validationErrors);
      return null;
    }
    axios
      .put(base_url + `new-password/${forgotToken}`, newPassword)
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000 });
        console.log("res", res.data.message);
        setTimeout(() => {
          navigate("/");
        }, 4000);
      });
    console.log("newPassword", newPassword);
  };

  return (
    <div className="authPage">
      <div className="container-fluid auth-wrapper d-flex justify-content-center">
        <ToastContainer />
        <div className="content-wrapper d-flex align-item-center justify-content-center flex-column p-3 page-width">
          <Link to="">
            <div class="logoDesign">
              <img src="/assets/images/team-icon.png" />
              <span className="teamtrackLogo">UserTeamTrack</span>
            </div>
          </Link>
          <div className="login-page">
            <div className="form">
              <h2 className="head">Generate New Password</h2>
              <form className="login-form">
                <div className="form-grp position-relative">
                  <input
                    className="pr-4"
                    type={passwordShow ? "text" : "password"}
                    placeholder="password"
                    name="new_password"
                    value={newPassword.new_password}
                    onChange={handleInput}
                  />
                  <i
                    className={
                      passwordShow
                        ? "fa fa-eye eyeIcon"
                        : "fa fa-eye-slash eyeIcon"
                    }
                    aria-hidden="true"
                    onClick={() => setPasswordShow(!passwordShow)}
                  ></i>
                </div>
                <div className="form-grp position-relative">
                  <input
                    className="pr-4"
                    type={passwordCnfShow ? "text" : "password"}
                    placeholder="confirm_password"
                    name="new_Cpassword"
                    value={newPassword.new_Cpassword}
                    onChange={handleInput}
                  />
                  <i
                    className={
                      passwordCnfShow
                        ? "fa fa-eye eyeIcon"
                        : "fa fa-eye-slash eyeIcon"
                    }
                    aria-hidden="true"
                    onClick={() => setPasswordCnfShow(!passwordCnfShow)}
                  ></i>
                  {error.check_password && (
                    <span className="validationErrors text-left d-block">
                      {error.check_password}
                    </span>
                  )}
                </div>
                <div className="btn">
                  <button onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer footer2">
        <Footer />
      </div>
    </div>
  );
}

export default NewPassword;
