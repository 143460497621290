import logo from "./logo.svg";
import "./App.css";
// import 'react-toastify'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./Components/Layout";
import Login from "./Components/AuthPage/Login";
import Forgot from "./Components/AuthPage/Forgot";
import Task from "./Components/Task";
import TaskDetail from "./Components/TaskDetail";
import SurveyForm from "./Components/SurveyForm";
import "react-toastify/dist/ReactToastify.css";
import NewPassword from "./Components/AuthPage/NewPassword";
import Profile from "./Components/Profile";
import DashBoard from "./Components/DashBoard";
import ClockIn from "./Components/ClockIn";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="*" element={<Login />} />

        <Route path="/main" element={<Layout />} />
        <Route path="/" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/task-show/:status" element={<Task />} />
        <Route path="/task-detail/:id" element={<TaskDetail />} />
        <Route path="/survey-from/:id" element={<SurveyForm />} />
        <Route path="/generate-password/:token" element={<NewPassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/clock-in" element={<ClockIn />} />
      </Routes>
      {/* <ToastContainer
                position="top-right"
                
            /> */}
    </BrowserRouter>
  );
}

export default App;
