import React, { useEffect, useMemo, useState } from "react";
import Layout from "./Layout";
import Select from "react-select";
import axios from "axios";
import { base_url } from "./constant";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SurveyForm() {
  const [reasonList, setReasonList] = useState([]);
  const [reasonListShow, setReasonListShow] = useState([]);
  const [otherPersonValue, setOtherPersonValue] = useState([]);

  const [visitedMode, setVisitedMode] = useState([]);
  const [remark, setRemark] = useState("");
  const [promiseAmount, setPromiseAmount] = useState("");
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [followUpStatus, setFollowUpStatus] = useState("1");
  const [errors, setErrors] = useState({});
  const [uploadFile, setUploadFile] = useState({
    client_photo: "",
    user_photo: "",
  });
  const [inputValue, setInputValue] = useState({
    personContact: "",
    remark: "",
    status: "",
  });
 
  const task_id = useParams();
  const navigate = useNavigate();
  const taskID = task_id.id;

  const token = JSON.parse(localStorage.getItem("subuserloginTokem"));
  const Token = token.accessToken;
  const header = {
    headers: {
      Authorization: `Bearer ${Token}`,
      // "content-type": "application/json"
      "Content-Type": "multipart/form-data",
    },
  };


  const handleInputChange = (e) => {
    setErrors({})
    const { name, value } = e.target;
    if(value == 16){
      setFollowUpDate("")
    }else{
      setFollowUpDate(new Date())
    }
    setInputValue({ ...inputValue, [name]: value })
  }

  const handleFile = (e, fieldName) => {
    const file = e.target.files[0];
    setUploadFile({ ...uploadFile, [fieldName]: file });
  };

  const handleDate = (e) => {

    setFollowUpDate(e);
  };

  //validation
  const isValid = () => {
    let valid = true;
    const newErrors = {};
    if (!inputValue.personContact) {
      newErrors.personContact = "Person contact is required";
      valid = false;
    }
    if (!uploadFile.client_photo) {
      newErrors.client_photo = "Client Photo Field is required";
      valid = false;
    }
    if (!uploadFile.user_photo) {
      newErrors.user_photo = "User Photo Field is required";
      valid = false;
    }
    if (!inputValue.remark) {
      newErrors.remark = "Remark Field is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;

  };
  //end validation

  const handleSubmit = (e) => {
    e.preventDefault();

    if (followUpDate == "") {
      setFollowUpStatus(0);
    }
    try {
      if (isValid()) {
        const formData = {
          task_id: taskID,
          person_contacted: inputValue.personContact,
          reason_id: inputValue.status,
          // reason_id me hum status ki value bhej rhe h
          // visit_mode: visitedMode.value,
          client_photo: uploadFile.client_photo,
          user_photo: uploadFile.user_photo,
          remark: inputValue.remark,
          // promise_amount: promiseAmount,
          followup_date: followUpDate,
          // status: followUpStatus,
          status: inputValue.status
        };

      

        axios.post(base_url + "add-survey", formData, header)
        .then((res) => {
          if(res.data.status == true ){
            toast.success(res.data.message);
            setTimeout(()=>{
              setInputValue({
                personContact: "",
                remark: "",
                status: "",
              })
              setUploadFile({
                client_photo: "",
                user_photo: "",
              });
              navigate('/dashboard');
           
              setFollowUpDate(new Date());
            },2000)
          }
         else{
          toast.warning(res.data.message);
         }
        })
        .catch((err) => {
      
          console.log(err);
        });
      }
    } catch (error) {
      console.log("error", error);
    }
   

  };

  // const memoizedResult = useMemo(() => {
  //   return;
  // }, []);


  // const [startDate, setStartDate] = useState(new Date());


  return (
    <Layout>
      <div className="customer-page login-page my-3">
        <ToastContainer position="top-right" />
        <div className="d-flex align-item-center justify-content-between hr-line">
          <h2 className="head" style={{ color: "#000" }}>
            Survey Form
          </h2>
          {/* <p>{moment(date)}</p> */}
        </div>
        <div className="form-detail">
          <form className="survey-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Person Contact</label>
                  <input
                    type="text"
                    name="personContact"
                    className="form-control"
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Person Contact"
                  />
                  {errors.personContact && (
                    <span className="validationErrors">
                      {errors.personContact}
                    </span>
                  )}
                  {/* <Select
                    defaultValue={options[0]}

                    value={options.value}
                    onChange={setPersonValue}
                    options={options}
                  />
                  {errors.person_contact && (
                    <span className="validationErrors">
                      {errors.person_contact}
                    </span>
                  )} */}
                </div>
              </div>


              {/* <div className="col-md-12">
                <div className="form-group">
                  <label>Contact Mode</label>
                  <Select
                    value={visitedMode}
                    onChange={setVisitedMode}
                    options={modeOptions}
                  />
                  {errors.visited_mode && (
                    <span className="validationErrors">
                      {errors.visited_mode}
                    </span>
                  )}
                </div>
              </div> */}

              {/* <div className="col-md-12">
                <div className="form-group">
                  <label>Action Code</label>
                  <Select
                    value={reasonListShow}
                    onChange={setReasonListShow}
                    options={reasonList_option}
                  />
                  {errors.reason_list && (
                    <span className="validationErrors">
                      {errors.reason_list}
                    </span>
                  )}
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="form-group">
                  <label>Client Photo Upload</label>
                  {/* <input
                                        type="file"
                                        className="form-control"
                                        name='client_photo'
                                        value={uploadFile.client_photo}
                                        onChange={handleFile}
                                    /> */}
                  <input
                    type="file"
                    name="client_photo"
                    className="form-control h-auto"
                    onChange={(e) => handleFile(e, "client_photo")}
                  />
                  {errors.client_photo && (
                    <span className="validationErrors">
                      {errors.client_photo}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>User Photo Upload</label>

                  <input
                    type="file"
                    name="user_photo"
                    className="form-control h-auto"
                    onChange={(e) => handleFile(e, "user_photo")}
                  />
                  {errors.user_photo && (
                    <span className="validationErrors">
                      {errors.user_photo}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    className="form-control"
                    style={{ height: "46px" }}
                    name="status"
                    onChange={(e) => handleInputChange(e)}
                    value={inputValue?.status}
                  >
                     <option value="">Select status</option>
                    <option value="16">Close</option>
                    <option value="15">Follow</option>

                  </select>
                </div>
              </div>
              {inputValue.status == "15" ?
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Follow Up Date</label>
                    <DatePicker
                      name="followup_date"
                      onChange={handleDate}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      selected={followUpDate}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                : " "}
              <div className="col-md-12">
                <div className="form-group">
                  <label>Remark</label>
                  <textarea
                    onChange={(e) => handleInputChange(e)}
                    name="remark"
                    rows={3}
                    cols={1}
                    placeholder="remark"
                    className="form-control"
                  />
                  {errors.remark && (
                    <span className="validationErrors">{errors.remark}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <button type="submit" className="survey-btn btn-primary finalSurvey-btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default SurveyForm;
