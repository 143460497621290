import axios from "axios";
import React, { useState, useEffect } from "react";
import { base_url } from "./constant";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Layout from "./Layout";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

function Profile() {
    const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("subuserloginTokem"));
  const Token = token.accessToken;
  const header = {
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "multipart/form-data"
    //   "content-type": "application/json",
    },
  };
  const [inputValue, setInputValue] = useState({});
  const gender_Value = inputValue.gender;
  
  const [gender, setGender] = useState(gender_Value);
  const [inputImage, setInputImage] = useState({
    userImage : ""
  });

  const [passwordShow, setPasswordShow] = useState(false);
  const [state, setState] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryData, setCountryData] = useState({
    label: "India",
    value: "India",
  });

  const country_option = [];
  const state_option = [];
  const country_Data = country.map((country_item) => {
    let country_obj = {
      label: country_item.name,
      value: country_item.name,
    };
    country_option.push(country_obj);

    const state_Data = state.map((state_item) => {
      if (country_item.code == state_item.country_code) {
        let state_obj = { label: state_item.default_name };
        state_option.push(state_obj);
      }
    });
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };


  const handleUserImage = (e, fieldName) => {
    const file =e.target.files[0];
    setInputImage({ ...inputImage, [fieldName]: file });
  };


  useEffect(() => {
    axios.get(base_url + "profile", header).then((res) => {
      setInputValue(res.data.data);
    
    });
    axios.get(base_url + "country-list").then((res) => {
      setCountry(res.data.data);
    });

    axios.get(base_url + "state-list").then((res) => {
      setState(res.data.data);
     
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setInputValue({});

    const formData = new FormData();
    formData.append("name", inputValue.name);
    // formData.append('parent_id', parent_id);
    formData.append("email", inputValue.email);
    formData.append("contact_number", inputValue.contact_number);
    formData.append("city", inputValue.city);
    formData.append("zipcode", inputValue.zipcode);
    formData.append("address", inputValue.address);
    formData.append("gender", gender);
    formData.append("country", countryData.label);
    formData.append("state", stateData.label);
    formData.append("profile_image", inputImage.userImage);
    formData.append("branch_id_mapping", inputValue?.branch_id_mapping);

    axios
      .post(base_url + "profile-update", formData, header)
      .then((res) => {
        toast(res.data.message);
        setTimeout(() => navigate("/dashboard"), 4000);
      
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleGender = (e) => {
    const value = e.target.value;
    setGender(value);
  };


  return (
    <Layout>
      <div className="customer-page login-page my-3">
        <ToastContainer />
        <div className="d-flex align-item-center justify-content-between hr-line">
          <h2 className="head">Profile</h2>
          {/* <p>{moment(date)}</p> */}
        </div>
        <div className="form-detail">
          <form className="survey-form" onSubmit={handleSubmit}>
            <div className="row">

              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={inputValue.name}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={inputValue.email}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Contact Number</label>
                  <input
                    type="number"
                    name="contact_number"
                    className="form-control"
                    value={inputValue.contact_number}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Country</label>
                  <Select
                    value={countryData}
                    onChange={setCountryData}
                    options={country_option}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    value={inputValue.city}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>State</label>
                  <Select
                    value={stateData}
                    onChange={setStateData}
                    options={state_option}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Zipcode</label>
                  <input
                    type="text"
                    name="zipcode"
                    className="form-control"
                    value={inputValue.zipcode}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Profile Image <sapn style={{ color: "red" }}>*</sapn>{" "}
                    {/* {inputValue.profile_image} */}
                    {/* <img src={`${FILE_VIEW_URL}${inputValue.profile_image}`} alt='profile' /> */}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="userImage"
                    onChange={(e) => handleUserImage(e, "userImage")}
                  />
                  {/* {errors.userImage && (
                    <span className="validationErrors">{errors.userImage}</span>
                  )} */}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="pr-2">Gender </label><br />
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    className="pr-1"
                    checked={gender == gender_Value}
                    onChange={handleGender}
                  />
                  <span style={{paddingRight:'40px'}}> Male </span>
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    checked={gender === gender_Value}
                    onChange={handleGender}
                    style={{paddingLeft:'10px'}}
                  />{" "}
                  <span> Female </span>
                </div>
              </div>

              {/* <div className='col-md-12'>
                                <div className="form-group">
                                    <label>Change Password</label>
                                    <div className='position-relative'>
                                        <input
                                           type={passwordShow ? "text" : "password"}
                                            name="password"
                                            className='form-control pr-5'
                                            value={inputValue.password}
                                            onChange={handleChange}
                                        />
                                        <i className={passwordShow ? "fa fa-eye eyeIcon" : "fa fa-eye-slash eyeIcon"} onClick={() => setPasswordShow(!passwordShow)}></i>
                                    </div>
                                </div>
                            </div> */}
              <div className="col-md-12">
                <button type="submit" className="survey-btn btn-primary">
                  Update Profile
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Profile;
