import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { base_url } from "./constant";

function TaskDetail() {
  const task_id = useParams();
  const navigate = useNavigate();
  const taskID = task_id.id;

  const [taskDetail, setTaskDetail] = useState("");
  const token = JSON.parse(localStorage.getItem("subuserloginTokem"));
  const Token = token.accessToken;
  const header = {
    headers: {
      Authorization: `Bearer ${Token}`,
      "content-type": "application/json",
    },
  };
  useEffect(() => {
    axios.get(base_url + `/task-view/${taskID}`, header).then((res) => {
      setTaskDetail(res.data.data);
    });
  }, []);

  const handleSurvey = () => {
    navigate(`/survey-from/${taskID}`);
  };
  const bgColor = localStorage.getItem("colorCode");
  const colorCode = localStorage.getItem("bgcolorCode");

  return (
    <Layout>
      <div className='customer-page login-page taskdetail my-3'>
        <div className="d-flex align-item-center justify-content-between hr-line">
          <h2 className="head mb-0" style={{color: `${colorCode}`}}>Task Detail</h2>
          {/* <p>{moment(date)}</p> */}
        </div>

        <div className="customer-detailpage">
          <div className={`task-head ${bgColor}`}>
            <p>
              <label>Name:-</label> <span>{taskDetail.customer_name}</span>
            </p>
            <p>
              <label>Phone No:-</label>{" "}
              <span>
                <Link to={`tel:${taskDetail.mobile_no}`}>
                  {" "}
                  {taskDetail.mobile_no}
                </Link>
              </span>
            </p>
            <p>
              <label>Email:-</label>{" "}
              <span>
                <Link to={`mailto:${taskDetail.email}`}>
                  {" "}
                  {taskDetail.email}
                </Link>
              </span>
            </p>
            <p>
              <label>Country:-</label> <span> {taskDetail.country}</span>
            </p>
            <p>
              <label>State:-</label> <span> {taskDetail.state}</span>
            </p>

            <p>
              <label>City:-</label> <span> {taskDetail.city}</span>
            </p>

           
            <p>
              <label>Address :-</label>{" "}
              <span>
                <Link to="#"> {taskDetail.address}</Link>
              </span>
            </p>

            <p>
              <label>Description:-</label>{" "}
              <span> {taskDetail.description}</span>
            </p>

           
            <div className="text-left">
            {taskDetail.status == false ? '' : 
            <button
              className='survey-btn w-auto'
              onClick={() => handleSurvey()}
              style={{color : `${colorCode}`, border:`1px solid ${colorCode}`}}
            >
              Update Field Visit Report
            </button>
            }
            
          </div>
          </div>
          {/* <div className='text-right'><button className='survey-btn btn-primary w-auto' onClick={()=>handleSurvey()}>Update Field Visit Report</button></div> */}
          {/* <div className="text-right">
            {taskDetail.status == false ? '' : 
            <button
              className='survey-btn btn-primary w-auto'
              onClick={() => handleSurvey()}
              style={{color : `${colorCode}`, border:`1px solid ${colorCode}`}}
            >
              Update Field Visit Report
            </button>
            }
            
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default TaskDetail;
