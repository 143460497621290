import React, { children } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <div>
      <div className="hero_area">
        {/* <!-- header section strats --> */}
        <Navbar />
        {/* <!-- end header section -->
    <!-- slider section --> */}
        <div className="d-flex flex-column main-conteiner">
          <div className="all-page">
            <main>{children}</main>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
        {/* <!-- end slider section --> */}
      </div>
    </div>
  );
}

export default Layout;
