import axios from "axios";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { base_url } from "../constant";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Footer";
function Forgot() {
  const [emailField, setEmailField] = useState({
    email: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEmailField({ ...emailField, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailField("");

    console.log("emailField", emailField);
    try {
      axios
        .post(base_url + "forgot-password", emailField)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 3000 });
          setTimeout(() => {}, 4000);
        })
        .catch((err) => {
          toast.success("Please enter valid email !");
        });
      // navigate("/generate-password")
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="authPage">
    <div className="container-fluid auth-wrapper d-flex justify-content-center">
      <ToastContainer />
      <div className="content-wrapper d-flex align-item-center justify-content-center flex-column p-3 page-width">
        <Link to="">
          <div class="logoDesign">
            <img src="/assets/images/team-icon.png" />
            <span className="teamtrackLogo">UserTeamTrack</span>
          </div>
        </Link>
        <div className="login-page">
          <div className="form">
            <h2 className="head">Forgot Password</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={emailField.email}
                onChange={handleInput}
              />

              <div className="btn">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div className="footer footer2">
    <Footer />
  </div>
  </div>
  );
}

export default Forgot;
